import { inject, provide } from '@vue/composition-api';
import { HttpService } from '@/services/network/HttpService';
import { TripOnlineConnection } from '@/services/network/online-services/TripOnlineConnection';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Container {
  // eslint-disable-next-line no-inner-declarations
  export function setup() {
    provide<HttpService>("HttpService", new HttpService());
    const tripOnlineConnection = new TripOnlineConnection();
    provide<TripOnlineConnection>("TripOnlineConnection", tripOnlineConnection);
  }
  // eslint-disable-next-line no-inner-declarations
  export function resolve<T>(key: string) : T {
    return inject<T>(key) as T;
  }
}
