import Vue from 'vue';
import VueCompositionAPI, { reactive } from '@vue/composition-api';
import { AppError } from '@/errors/AppError';

Vue.use(VueCompositionAPI);

const appErrors = reactive({
  errors: new Array<AppError>(),
  removeError: (appError: AppError) => {
    appErrors.errors = appErrors.errors.filter((e) => e !== appError);
  },
  addError: (appError: AppError) => {
    appErrors.errors.push(appError);
  },
});

export function useAppErrors() {
  return appErrors;
}
