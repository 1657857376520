import axios, { AxiosResponse } from 'axios';
import { ClassConstructor, plainToClass } from 'class-transformer';
import { HttpServiceOptions } from '@/services/network/HttpServiceOptions';
import { useAppErrors } from '@/errors/app-errors-composition-api';

// TODO понять как ловить ошибки interseptors?
export class HttpService {
  constructor() {
    const appErrors = useAppErrors();

    axios.interceptors.response.use((response) => {
      return response;
    }, (error: any) => {
      if (!error.response) {
        alert('NETWORK ERROR');
      } else {
        const code = error.response.status;
        const response = error.response.data;
        const originalRequest = error.config;
        if (code === 400) {
          const { errors } = response;

          const keys = Object.keys(errors);
          for (let i = 0; i < keys.length; i += 1) {
            const key = keys[i];
            const concreteErrors = errors[key] as [];

            for (let j = 0; j < concreteErrors.length; j++) {
              const concreteError = {
                title: concreteErrors[j],
              };

              setTimeout(() => {
                appErrors.removeError(concreteError);
              }, 10_000);
              appErrors.addError(concreteError);
            }
          }
        }
      }
      return Promise.reject(error);
    });
  }

  public async get<T>(
    url: string,
    options?: HttpServiceOptions<T>,
  ): Promise<AxiosResponse<T>> {
    const response = await axios.get(url, {
      params: options?.data,
    });
    response.data = this.mapResponseData(options?.constructorFunction, response.data);
    return response;
  }

  public async post<TI, TO>(
    url: string,
    options?: HttpServiceOptions<TI>,
  ): Promise<AxiosResponse<TO>> {
    const response = await axios.post(url, options?.data);
    response.data = this.mapResponseData(options?.constructorFunction, response.data);

    return response;
  }

  public async put<TI, TO>(
    url: string,
    options?: HttpServiceOptions<TI>,
  ): Promise<AxiosResponse<TO>> {
    const response = await axios.put(url, options?.data);
    response.data = this.mapResponseData(options?.constructorFunction, response.data);

    return response;
  }

  private mapResponseData(
    constructorFunction: ClassConstructor<any> | null | undefined,
    data: any,
  ) {
    if (constructorFunction != null) {
      return plainToClass(constructorFunction, data);
    }
    return data;
  }
}
