import { Type } from 'class-transformer';
import { TripEventType } from '@/models/trip/TripEventType';

export class TripEvent {
  public id = ''

  @Type(() => Date)
  public date!: Date

  public tripVersionId = ''

  @Type(() => TripEventType)
  public type!: TripEventType
}
