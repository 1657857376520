














import { defineComponent } from '@vue/composition-api';
import { CurrentUser } from '@/models/user/CurrentUser';

export default defineComponent({
  name: 'UserInfoInAppBar',
  props: {
    user: {
      type: CurrentUser,
      required: true,
    },
  },
});
