import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  RetryContext,
} from '@microsoft/signalr';
import { TripEventModelWithTask } from '@/models/trip/TripEventModelWithTask';
import { ClassConstructor, plainToClass } from 'class-transformer';
import { Ref, ref, UnwrapRef } from '@vue/composition-api';

export class TripOnlineConnection {
  private hubConnection: HubConnection;

  private _state: Ref<UnwrapRef<HubConnectionState>> = ref(HubConnectionState.Disconnected);

  constructor() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl('/ws/trip')
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
          return 1000;
        },
      })
      .build();
    this.hubConnection.onreconnected(() => {
      this.setStatus();
    });
    this.hubConnection.onreconnecting(() => {
      this.setStatus();
    });
    this.hubConnection.onclose(() => {
      this.setStatus();
    });
  }

  public get state(): Ref<UnwrapRef<HubConnectionState>> {
    return this._state;
  }

  public async start() : Promise<void> {
    await this.hubConnection.start();
    this.setStatus();
  }

  public async stop() : Promise<void> {
    await this.hubConnection.stop();
    this.setStatus();
  }

  public onTripChanged(callback: (tripEventTask: TripEventModelWithTask) => void) {
    this.hubConnection.on('TripTaskChanged', (data) => {
      callback(this.mapResponseData(TripEventModelWithTask, data));
    });
  }

  public onTripPlanChanged(callback: () => void) {
    this.hubConnection.on('TripPlanChanged', (data) => {
      callback();
    });
  }

  public onTripEnded(callback: () => void) {
    this.hubConnection.on('TripEnded', (data) => {
      callback();
    });
  }

  private setStatus() {
    this._state.value = this.hubConnection.state;
  }

  private mapResponseData(
    constructorFunction: ClassConstructor<any> | null | undefined,
    data: any,
  ) {
    if (constructorFunction != null) {
      return plainToClass(constructorFunction, data);
    }
    return data;
  }
}
