import { Depot } from '@/models/Depot';
import { Role } from '@/models/user/Role';

export class CurrentUser {
  public id!: string

  public surname!: string

  public name!: string

  public middleName!: string

  public avatarUrl!: string

  public depot!: Depot

  public role!: Role

  constructor(init?: Partial<CurrentUser>) {
    Object.assign(this, init);
  }
}
