








import { computed, defineComponent, ref } from '@vue/composition-api';
import dateFormats from '@/helpers/DateFormats';

export default defineComponent({
  name: 'CurrentDatePlaceInfo',
  props: {
    place: {
      type: String,
      required: false,
    },
  },
  setup: (props, ctx) => {
    const { toTime, toTextMonthDate } = dateFormats();

    const currentDate = ref(new Date());

    setInterval(() => {
      currentDate.value = new Date();
    }, 1000);

    const date = computed(() => {
      return toTextMonthDate(currentDate.value);
    });

    const time = computed(() => {
      return toTime(currentDate.value);
    });

    return { time, date };
  },
});
