







import {
  defineComponent, nextTick, reactive, ref, watch, onBeforeMount, computed,
} from '@vue/composition-api';
import Layout from '@/components/base/Layout.vue';
import { CurrentUser } from '@/models/user/CurrentUser';
import { Depot } from '@/models/Depot';
import { Role } from '@/models/user/Role';
import { useRoute, useRouter } from '@/router/router-composition-api';
import { useTitle } from '@/composables/PageTitle';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export default defineComponent({
  name: 'App',
  components: {
    Layout,
  },
  setup: (_, {root}) => {
    const route = useRoute();
    const title = useTitle();

    onBeforeMount(() => {
      const token = localStorage.getItem('token');

      if (token == null) {
        root.$router.push('/login');
        return;
      }

      try {
        const decodedJwt = jwtDecode<JwtPayload>(token);

        if (Date.now() >= decodedJwt.exp! * 1000) {
          root.$router.push('/login');
          return;
        }
      } catch (e) {
        root.$router.push('/login');
      }
    })


    nextTick(() => {
      watch(() => route.meta?.label, (label: string) => {
        title.value = label;
      });

      title.value = route.meta.label;
    });

    const user = reactive(new CurrentUser({
      avatarUrl: 'https://cdn.vuetifyjs.com/images/john.jpg',
      id: '1',
      surname: 'Искаков',
      name: 'Нурлан',
      middleName: 'Даниярулы',
      depot: new Depot({
        id: '1',
        name: 'ТЧЭ-28 Алматы',
      }),
      role: new Role({
        id: '1',
        name: 'ТЧД',
      }),
    }));    
    
    const noLayout = computed(() => route.meta.noLayout);

    return {
      user,
      title,    
      noLayout,
    };
  },
});
