import { format as f, parse } from 'date-fns';
import ruLocale from 'date-fns/locale/ru/index';

const timeZoneOffset = new Date(0).getTimezoneOffset();

export default function dateFormats() {
  const dateFormat = 'dd.MM.yyyy';
  const iso8601Format = 'yyyy-MM-dd';

  const toDate = (date: Date) => {
    return f(date, dateFormat, { locale: ruLocale });
  };

  const toISO8601 = (date: Date) => {
    return f(date, iso8601Format, { locale: ruLocale });
  };

  const ISOStringDateToDate = (dateString: string) => {
    return parse(dateString, iso8601Format, 0, { locale: ruLocale });
  };

  const stringDateToDate = (dateString: string) => {
    return parse(dateString, dateFormat, 0, { locale: ruLocale });
  };

  const toTextMonthDate = (date: Date) => {
    return f(date, 'dd.MMMM.yyyy', { locale: ruLocale });
  };

  const toOnlyTextMonthDate = (date: Date) => {
    return f(date, 'LLLL yyyy', { locale: ruLocale });
  };

  const toTime = (date: Date) => {
    return f(date, 'HH:mm');
  };

  const secondsToTime = (seconds: number, format: string) => {
    return f(seconds * 1000 + timeZoneOffset * 60 * 1000, format);
  };

  const toFullDateTime = (date: Date) => {
    return f(date, 'dd.MM.yy HH:mm');
  };

  const toDateRange = (startDate: Date, endDate: Date | null) => {
    const startDateString = toDate(startDate);
    const endDateString = endDate == null ? 'настоящее' : toDate(endDate);

    return `(${startDateString} - ${endDateString})`;
  };

  return {
    toDate,
    toOnlyTextMonthDate,
    toTextMonthDate,
    toISO8601,
    toTime,
    secondsToTime,
    toFullDateTime,
    stringDateToDate,
    ISOStringDateToDate,
    toDateRange,
  };
}
