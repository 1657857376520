/* eslint-disable @typescript-eslint/ban-ts-comment */
import 'es6-shim';
import 'reflect-metadata';
import Vue from 'vue';
// @ts-ignore
import VueDragscroll from 'vue-dragscroll';
import { Chart, registerables } from 'chart.js';
import HighchartsVue from 'highcharts-vue';
import VueCompositionAPI from '@vue/composition-api';
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LControlZoom,
  LControl,
  LPolyline,
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Container } from '@/Container';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './styles/rd-style.styl';
import 'vue2-animate/dist/vue2-animate.min.css';
import 'animate.css';

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);
Vue.use(VueDragscroll);

Chart.register(...registerables);

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-popup', LPopup);
Vue.component('l-control-zoom', LControlZoom);
Vue.component('l-control', LControl);
Vue.component('l-polyline', LPolyline);
Vue.use(HighchartsVue);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => {
    Container.setup();
    return h(App);
  },
}).$mount('#app');
