import { Type } from 'class-transformer';
import { TripEvent } from '@/models/trip/TripEvent';
import { TripTask } from '@/models/trip/TripTask';
import { TripItemModel } from '@/models/trip/TripItemModel';

export class TripEventModelWithTask extends TripItemModel {
  @Type(() => TripEvent)
  public tripEvent: TripEvent | null = null;

  @Type(() => TripTask)
  public tripTask!: TripTask;

  get date() : Date {
    if (this.tripEvent != null) {
      return this.tripEvent.date;
    }

    return this.tripTask.planningExecutionTime;
  }
}
