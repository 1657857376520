




















































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { useRoute, useRouter } from '@/router/router-composition-api';
import PrintIcon from '@/components/base/action-icons/PrintIcon.vue';
import ShareIcon from '@/components/base/action-icons/ShareIcon.vue';
import CalendarIcon from '@/components/base/action-icons/CalendarIcon.vue';
import ChatIcon from '@/components/base/action-icons/ChatIcon.vue';
import MailIcon from '@/components/base/action-icons/MailIcon.vue';
import PhoneIcon from '@/components/base/action-icons/PhoneIcon.vue';
import NotificationIcon from '@/components/base/action-icons/NotificationIcon.vue';
import CurrentDatePlaceInfo from '@/components/base/CurrentDatePlaceInfo.vue';
import AppBarSearch from '@/components/base/AppBarSearch.vue';
import UserInfoInAppBar from '@/components/base/UserInfoInAppBar.vue';
import { CurrentUser } from '@/models/user/CurrentUser';
import { RouteConfigMeta } from '@/router/RouteConfigMeta';
import { useAppErrors } from '@/errors/app-errors-composition-api';
import { AppError } from '@/errors/AppError';

export default defineComponent({
  name: 'Layout',
  components: {
    UserInfoInAppBar,
    AppBarSearch,
    CurrentDatePlaceInfo,
    NotificationIcon,
    PhoneIcon,
    MailIcon,
    ChatIcon,
    CalendarIcon,
    ShareIcon,
    PrintIcon,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    user: {
      type: Object as PropType<CurrentUser>,
      required: true,
    },
  },
  setup: () => {
    const menuIsOpen = ref(false);
    const appErrors = useAppErrors();

    const removeError = (error: AppError) => {
      appErrors.removeError(error);
    };

    const currentRoute = useRoute();
    const router = useRouter();

    const mainRoutes = router.getRoutes()
      .filter((r) => r.meta != null && Object.keys(r.meta).length)
      .map((r) => {
        return {
          ...r,
          meta: r.meta as RouteConfigMeta,
        };
      })
      .filter((r) => r.meta.inDrawer);

    const isCurrentRoute = (route: string) => {
      return currentRoute.path === route;
    };

    return {
      menuIsOpen,
      mainRoutes,
      appErrors,
      isCurrentRoute,
      removeError,
    };
  },
});
