import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { DictionariesPageProps } from '@/views/dictionaries/DictionariesPageProps';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/contracts',
    name: 'Contracts',
    meta: {
      inDrawer: true,
      icon: 'description',
      label: 'Контракты',
    },
    component: () => import(/* webpackChunkName: "contracts" */ '../views/Contracts.vue'),
  },
  {
    path: '/trip',
    name: 'Trip',
    component: () => import(/* webpackChunkName: "trip" */ '../views/Trip.vue'),
  },
  {
    path: '/trips',
    name: 'Trips',
    meta: {
      inDrawer: true,
      icon: 'article',
      label: 'Реестр поездок',
    },
    component: () => import(/* webpackChunkName: "trips" */ '../views/Trips.vue'),
  },
  {
    path: '/smart-dispatcher',
    name: 'SmartDispatcher',
    meta: {
      inDrawer: true,
      icon: 'dashboard',
      label: 'Оперативное окно',
    },
    component: () => import(/* webpackChunkName: "smart-dispatcher" */ '../views/SmartDispatcher.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      isDrawer: false,
      noLayout: true,
      label: 'Логин',
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/dictionaries/:dictionary?',
    name: 'Dictionaries',
    meta: {
      inDrawer: true,
      icon: 'book',
      label: 'Справочники',
    },
    props: (route) => {
      return new DictionariesPageProps({
        dictionary: route.params.dictionary,
        page: route.query?.page == null ? null : Number(route.query.page),
      });
    },
    component: () => import(/* webpackChunkName: "dictionaries" */ '../views/dictionaries/Dictionaries.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
