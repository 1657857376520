import { Type } from 'class-transformer';
import { TripTaskType } from '@/models/trip/TripTaskType';

export class TripTask {
  public id = ''

  @Type(() => TripTaskType)
  public type!: TripTaskType

  public message = ''

  @Type(() => Date)
  public startTime!: Date

  @Type(() => Date)
  public planningExecutionTime!: Date

  @Type(() => Date)
  public executionTime: Date | null = null

  public executed!: boolean

  public tripId!: string

  public tripVersionId!: string

  get date(): Date {
    if (this.executionTime != null) {
      return this.executionTime;
    }

    return this.planningExecutionTime;
  }
}
